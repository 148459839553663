import React from "react";
import "./style.css";

export const Web = () => {
  return (
    <div className="web">
      <div className="overlap-wrapper">
        <div className="overlap">
          <img className="background" alt="Background" src="/img/background.png" />
          <div className="bacckground" />
          <div className="content">
            <img className="group" alt="Group" src="/img/group-1-1.png" />
          </div>
          <img className="img" alt="Group" src="/img/group-2.png" />
          <img className="graphic" alt="Graphic" src="/img/graphic.svg" />
          <div className="IMG">
            <div className="div" />
          </div>
          <div className="frame-wrapper">
            <div className="frame">
              <div className="frame-2">
                <div className="frame-3">
                  <div className="frame-4">
                    <div className="div-wrapper">
                      <div className="frame-4">
                        <div className="overlap-group-wrapper">
                          <div className="overlap-group">
                            <div className="content-2">
                              <button className="button">
                                <div className="text-wrapper">Get in Touch</div>
                              </button>
                              <div className="typography">
                                <div className="text-wrapper-2">Data driven decision making</div>
                                <p className="fourleaf-offers">
                                  <br />
                                  Fourleaf offers analytical tools and expertise in liquidity management, empowering
                                  token issuers and institutions to make well-informed decisions.
                                </p>
                              </div>
                            </div>
                            <div className="chart-wrapper">
                              <div className="chart">
                                <div className="overlap-2">
                                  <img className="chart-2" alt="Chart" src="/img/chart-2-1.png" />
                                  <div className="list-column-wrapper">
                                    <div className="list-column">
                                      <div className="column">
                                        <div className="overlap-group-2">
                                          <img className="stick" alt="Stick" src="/img/stick-75.svg" />
                                          <div className="candle" />
                                        </div>
                                      </div>
                                      <div className="column-2">
                                        <div className="overlap-3">
                                          <img className="stick-2" alt="Stick" src="/img/stick-74.svg" />
                                          <div className="candle-2" />
                                        </div>
                                      </div>
                                      <div className="column-3">
                                        <div className="overlap-3">
                                          <img className="stick-2" alt="Stick" src="/img/stick-73.svg" />
                                          <div className="candle-3" />
                                        </div>
                                      </div>
                                      <div className="column-4">
                                        <div className="overlap-3">
                                          <img className="stick-2" alt="Stick" src="/img/stick-72.svg" />
                                          <div className="candle-4" />
                                        </div>
                                      </div>
                                      <div className="column-5">
                                        <div className="overlap-4">
                                          <img className="stick-3" alt="Stick" src="/img/stick-71.svg" />
                                          <div className="candle-5" />
                                        </div>
                                      </div>
                                      <div className="column-6">
                                        <div className="overlap-5">
                                          <img className="stick-4" alt="Stick" src="/img/stick-70.svg" />
                                          <div className="candle-6" />
                                        </div>
                                      </div>
                                      <div className="column-7">
                                        <div className="overlap-6">
                                          <img className="stick-5" alt="Stick" src="/img/stick-69.svg" />
                                          <div className="candle-7" />
                                        </div>
                                      </div>
                                      <div className="column-8">
                                        <div className="overlap-6">
                                          <img className="stick-5" alt="Stick" src="/img/stick-68.svg" />
                                          <div className="candle-8" />
                                        </div>
                                      </div>
                                      <div className="column-9">
                                        <div className="overlap-7">
                                          <img className="stick-6" alt="Stick" src="/img/stick-67.svg" />
                                          <div className="candle-9" />
                                        </div>
                                      </div>
                                      <div className="column-10">
                                        <div className="overlap-8">
                                          <img className="stick-7" alt="Stick" src="/img/stick-66.svg" />
                                          <div className="candle-10" />
                                        </div>
                                      </div>
                                      <div className="column-11">
                                        <div className="overlap-9">
                                          <img className="stick-8" alt="Stick" src="/img/stick-65.svg" />
                                          <div className="candle-11" />
                                        </div>
                                      </div>
                                      <div className="column-12">
                                        <div className="overlap-10">
                                          <img className="stick-9" alt="Stick" src="/img/stick-64.svg" />
                                          <div className="candle-12" />
                                        </div>
                                      </div>
                                      <div className="column-13">
                                        <div className="overlap-11">
                                          <img className="stick-10" alt="Stick" src="/img/stick-63.svg" />
                                          <div className="candle-13" />
                                        </div>
                                      </div>
                                      <div className="column-14">
                                        <div className="overlap-11">
                                          <img className="stick-10" alt="Stick" src="/img/stick-62.svg" />
                                          <div className="candle-14" />
                                        </div>
                                      </div>
                                      <div className="column-15">
                                        <div className="overlap-11">
                                          <img className="stick-10" alt="Stick" src="/img/stick-61.svg" />
                                          <div className="candle-15" />
                                        </div>
                                      </div>
                                      <div className="column-16">
                                        <div className="overlap-12">
                                          <img className="stick-11" alt="Stick" src="/img/stick-60.svg" />
                                          <div className="candle-16" />
                                        </div>
                                      </div>
                                      <div className="column-17">
                                        <div className="overlap-13">
                                          <img className="stick-12" alt="Stick" src="/img/stick-59.svg" />
                                          <div className="candle-17" />
                                        </div>
                                      </div>
                                      <div className="column-18">
                                        <div className="overlap-14">
                                          <img className="stick-13" alt="Stick" src="/img/stick-58.svg" />
                                          <div className="candle-18" />
                                        </div>
                                      </div>
                                      <div className="column-19">
                                        <div className="overlap-14">
                                          <img className="stick-13" alt="Stick" src="/img/stick-57.svg" />
                                          <div className="candle-19" />
                                        </div>
                                      </div>
                                      <div className="column-20">
                                        <div className="overlap-14">
                                          <img className="stick-13" alt="Stick" src="/img/stick-56.svg" />
                                          <div className="candle-19" />
                                        </div>
                                      </div>
                                      <div className="column-21">
                                        <div className="overlap-15">
                                          <img className="stick-14" alt="Stick" src="/img/stick-55.svg" />
                                          <div className="candle-20" />
                                        </div>
                                      </div>
                                      <div className="column-22">
                                        <div className="overlap-16">
                                          <img className="stick-15" alt="Stick" src="/img/stick-54.svg" />
                                          <div className="candle-21" />
                                        </div>
                                      </div>
                                      <div className="column-23">
                                        <div className="overlap-17">
                                          <img className="stick-16" alt="Stick" src="/img/stick-53.svg" />
                                          <div className="candle-22" />
                                        </div>
                                      </div>
                                      <div className="column-24">
                                        <div className="overlap-18">
                                          <img className="stick-17" alt="Stick" src="/img/stick-52.svg" />
                                          <div className="candle-23" />
                                        </div>
                                      </div>
                                      <div className="column-25">
                                        <div className="overlap-19">
                                          <img className="stick-18" alt="Stick" src="/img/stick-51.svg" />
                                          <div className="candle-24" />
                                        </div>
                                      </div>
                                      <div className="column-26">
                                        <div className="overlap-11">
                                          <img className="stick-10" alt="Stick" src="/img/stick-50.svg" />
                                          <div className="candle-25" />
                                        </div>
                                      </div>
                                      <div className="column-27">
                                        <div className="overlap-20">
                                          <img className="stick-10" alt="Stick" src="/img/stick-49.svg" />
                                          <div className="candle-26" />
                                        </div>
                                      </div>
                                      <div className="column-28">
                                        <div className="overlap-21">
                                          <img className="stick-19" alt="Stick" src="/img/stick-48.svg" />
                                          <div className="candle-17" />
                                        </div>
                                      </div>
                                      <div className="column-29">
                                        <div className="overlap-11">
                                          <img className="stick-10" alt="Stick" src="/img/stick-47.svg" />
                                          <div className="candle-27" />
                                        </div>
                                      </div>
                                      <div className="column-30">
                                        <img className="stick-10" alt="Stick" src="/img/stick-46.svg" />
                                        <div className="candle-28" />
                                      </div>
                                      <div className="column-31">
                                        <div className="overlap-22">
                                          <img className="stick-20" alt="Stick" src="/img/stick-45.svg" />
                                          <div className="candle-29" />
                                        </div>
                                      </div>
                                      <div className="column-32">
                                        <div className="overlap-6">
                                          <img className="stick-5" alt="Stick" src="/img/stick-44.svg" />
                                          <div className="candle-30" />
                                        </div>
                                      </div>
                                      <div className="column-33">
                                        <div className="overlap-21">
                                          <img className="stick-19" alt="Stick" src="/img/stick-43.svg" />
                                          <div className="candle-31" />
                                        </div>
                                      </div>
                                      <div className="column-34">
                                        <div className="overlap-6">
                                          <img className="stick-5" alt="Stick" src="/img/stick-42.svg" />
                                          <div className="candle-32" />
                                        </div>
                                      </div>
                                      <div className="column-35">
                                        <div className="overlap-23">
                                          <img className="stick-14" alt="Stick" src="/img/stick-41.svg" />
                                          <div className="candle-33" />
                                        </div>
                                      </div>
                                      <div className="column-36">
                                        <div className="overlap-24">
                                          <img className="stick-21" alt="Stick" src="/img/stick-40.svg" />
                                          <div className="candle-34" />
                                        </div>
                                      </div>
                                      <div className="column-37">
                                        <div className="overlap-25">
                                          <img className="stick-22" alt="Stick" src="/img/stick-39.svg" />
                                          <div className="candle-35" />
                                        </div>
                                      </div>
                                      <div className="column-38">
                                        <div className="overlap-11">
                                          <img className="stick-10" alt="Stick" src="/img/stick-38.svg" />
                                          <div className="candle-36" />
                                        </div>
                                      </div>
                                      <div className="column-39">
                                        <div className="overlap-26">
                                          <img className="stick-23" alt="Stick" src="/img/stick-37.svg" />
                                          <div className="candle-37" />
                                        </div>
                                      </div>
                                      <div className="column-40">
                                        <div className="overlap-26">
                                          <img className="stick-23" alt="Stick" src="/img/stick-36.svg" />
                                          <div className="candle-38" />
                                        </div>
                                      </div>
                                      <div className="column-41">
                                        <div className="overlap-27">
                                          <img className="stick-24" alt="Stick" src="/img/stick-35.svg" />
                                          <div className="candle-39" />
                                        </div>
                                      </div>
                                      <div className="column-42">
                                        <div className="overlap-28">
                                          <img className="stick-25" alt="Stick" src="/img/stick-34.svg" />
                                          <div className="candle-40" />
                                        </div>
                                      </div>
                                      <div className="column-43">
                                        <div className="overlap-28">
                                          <img className="stick-25" alt="Stick" src="/img/stick-33.svg" />
                                          <div className="candle-40" />
                                        </div>
                                      </div>
                                      <div className="column-44">
                                        <div className="overlap-group-3">
                                          <img className="stick-26" alt="Stick" src="/img/stick-32.svg" />
                                          <div className="candle-41" />
                                        </div>
                                      </div>
                                      <div className="column-45">
                                        <div className="overlap-5">
                                          <img className="stick-26" alt="Stick" src="/img/stick-31.svg" />
                                          <div className="candle-42" />
                                        </div>
                                      </div>
                                      <div className="column-46">
                                        <div className="overlap-29">
                                          <img className="stick-27" alt="Stick" src="/img/stick-30.svg" />
                                          <div className="candle-43" />
                                        </div>
                                      </div>
                                      <div className="column-47">
                                        <div className="overlap-11">
                                          <img className="stick-10" alt="Stick" src="/img/stick-29.svg" />
                                          <div className="candle-44" />
                                        </div>
                                      </div>
                                      <div className="column-48">
                                        <div className="overlap-4">
                                          <img className="stick-28" alt="Stick" src="/img/stick-28.svg" />
                                          <div className="candle-45" />
                                        </div>
                                      </div>
                                      <div className="column-49">
                                        <div className="overlap-4">
                                          <img className="stick-28" alt="Stick" src="/img/stick-27.svg" />
                                          <div className="candle-45" />
                                        </div>
                                      </div>
                                      <div className="column-50">
                                        <div className="overlap-30">
                                          <img className="stick-29" alt="Stick" src="/img/stick-26.svg" />
                                          <div className="candle-46" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="LIST-CRYPTO">
                          <div className="card-assets">
                            <div className="text-wrapper-3">BTC</div>
                            <div className="text-wrapper-4">USD 53,260.20</div>
                            <div className="text-wrapper-5">Bitcoin</div>
                            <img className="chart-3" alt="Chart" src="/img/chart-1.png" />
                            <div className="frame-5">
                              <img className="bold-arrow-up" alt="Bold arrow up" src="/img/bold-arrow-up-3-3.svg" />
                              <div className="text-wrapper-6">7.2%</div>
                            </div>
                            <div className="img-2">
                              <div className="vector-wrapper">
                                <img className="vector" alt="Vector" src="/img/vector-47.svg" />
                              </div>
                            </div>
                          </div>
                          <div className="card-assets-2">
                            <div className="text-wrapper-7">ETH</div>
                            <div className="text-wrapper-4">USD 53,260.20</div>
                            <div className="text-wrapper-5">Ethereum</div>
                            <img className="chart-4" alt="Chart" src="/img/chart-3.png" />
                            <div className="frame-6">
                              <img className="bold-arrow-up" alt="Bold arrow up" src="/img/bold-arrow-up-3-2.svg" />
                              <div className="text-wrapper-6">1.8%</div>
                            </div>
                            <img className="img-2" alt="Frame" src="/img/frame-1.svg" />
                          </div>
                          <div className="card-assets-3">
                            <div className="text-wrapper-8">USDT</div>
                            <div className="text-wrapper-9">USD 53,260.20</div>
                            <div className="text-wrapper-5">USDT</div>
                            <img className="img-2" alt="Frame" src="/img/frame.svg" />
                            <img className="chart-3" alt="Chart" src="/img/chart-1.png" />
                            <div className="frame-7">
                              <img className="bold-arrow-up" alt="Bold arrow up" src="/img/bold-arrow-up-3.svg" />
                              <div className="text-wrapper-6">3.54%</div>
                            </div>
                          </div>
                          <div className="card-assets-4">
                            <div className="frame-8">
                              <img className="bold-arrow-up" alt="Bold arrow up" src="/img/bold-arrow-up-3.svg" />
                              <div className="text-wrapper-6">3.24%</div>
                            </div>
                            <div className="text-wrapper-10">BNB</div>
                            <div className="text-wrapper-9">USD 53,260.20</div>
                            <div className="text-wrapper-5">BNB</div>
                            <img className="img-2" alt="Btc" src="/img/btc.svg" />
                            <img className="chart-3" alt="Chart" src="/img/chart-1.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="content-3">
                      <div className="text">
                        <button className="button-2">
                          <div className="text-wrapper">Get in Touch</div>
                        </button>
                        <div className="overlap-group-4">
                          <p className="subtitle">
                            We aggregate and distribute real-time and historical data from both centralized and
                            decentralized exchanges.
                          </p>
                          <div className="title">Data Collection And Distribution</div>
                        </div>
                      </div>
                      <div className="features">
                        <div className="feature">
                          <div className="group-wrapper">
                            <div className="group-2">
                              <div className="frame-9">
                                <img className="group-3" alt="Group" src="/img/group-1.png" />
                                <div className="title-wrapper">
                                  <p className="p">
                                    Monitor and track market
                                    <br />
                                    liquidity across exchanges
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="group-4">
                          <div className="group-5">
                            <div className="feature-wrapper">
                              <div className="feature-2">
                                <div className="frame-10">
                                  <img className="group-6" alt="Group" src="/img/group-11103.png" />
                                  <div className="title-wrapper">
                                    <p className="p">
                                      Real-time price feeds
                                      <br /> and customized metrics
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-7">
                    <div className="frame-11">
                      <img className="background-2" alt="Background" src="/img/background-1.png" />
                      <div className="CALL-TO-ACTION">
                        <div className="call-to-action">
                          <div className="text-2">
                            <p className="text-wrapper-11">Learn more about our services and how we can collaborate</p>
                            <p className="text-wrapper-12">Get in touch with us now</p>
                          </div>
                          <button className="label-wrapper">
                            <div className="label">Get in Touch</div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-8">
                  <div className="group-9">
                    <div className="content-4">
                      <div className="frame-12">
                        <div className="text-3">
                          <div className="feature-3">
                            <div className="overlap-group-5">
                              <div className="icon" />
                              <img className="group-10" alt="Group" src="/img/group-11109.png" />
                            </div>
                            <div className="frame-13">
                              <p className="title-2">
                                Gain comprehensive insights into the intricacies <br />
                                of market-making
                              </p>
                            </div>
                          </div>
                          <div className="feature-4">
                            <div className="img-wrapper">
                              <img className="group-11" alt="Group" src="/img/group.png" />
                            </div>
                            <div className="frame-14">
                              <p className="title-3">
                                Receive assistance in reviewing and negotiating <br />
                                otherwise complex deals.
                              </p>
                            </div>
                          </div>
                          <div className="feature-5">
                            <div className="img-wrapper">
                              <img className="group-12" alt="Group" src="/img/group-11107.png" />
                            </div>
                            <div className="frame-14">
                              <p className="title-3">
                                Establish a healthy competition for market-making <br />
                                around your token
                              </p>
                            </div>
                          </div>
                          <div className="frame-15">
                            <div className="title-4">Market-making Aggregation</div>
                            <p className="subtitle-2">
                              We demystify the process of market-making and assist token issuers in making informed
                              decisions while navigating the complexities of a token launch.
                            </p>
                          </div>
                        </div>
                        <div className="bittrex-inc-logo" />
                        <div className="rectangle" />
                      </div>
                    </div>
                    <div className="div-2">
                      <div className="group-13">
                        <div className="overlap-31">
                          <div className="div-2">
                            <div className="overlap-group-6">
                              <div className="ellipse" />
                              <div className="ellipse-2" />
                            </div>
                          </div>
                          <div className="group-14">
                            <div className="overlap-32">
                              <div className="chart-5">
                                <div className="overlap-33">
                                  <img className="vector-2" alt="Vector" src="/img/vector-72.svg" />
                                  <div className="list-column-2">
                                    <div className="column-51">
                                      <div className="overlap-group-3">
                                        <img className="stick-30" alt="Stick" src="/img/stick-25.svg" />
                                        <div className="candle-47" />
                                      </div>
                                    </div>
                                    <div className="column-52">
                                      <div className="overlap-21">
                                        <img className="stick-31" alt="Stick" src="/img/stick-24.svg" />
                                        <div className="candle-48" />
                                      </div>
                                    </div>
                                    <div className="column-53">
                                      <div className="overlap-23">
                                        <div className="candle-49" />
                                        <img className="stick-32" alt="Stick" src="/img/stick-23.svg" />
                                      </div>
                                    </div>
                                    <div className="column-54">
                                      <div className="overlap-34">
                                        <img className="stick-33" alt="Stick" src="/img/stick-22.svg" />
                                        <div className="candle-50" />
                                      </div>
                                    </div>
                                    <div className="column-55">
                                      <div className="overlap-34">
                                        <img className="stick-33" alt="Stick" src="/img/stick-21.svg" />
                                        <div className="candle-51" />
                                      </div>
                                    </div>
                                    <div className="column-56">
                                      <div className="overlap-6">
                                        <img className="stick-34" alt="Stick" src="/img/stick-20.svg" />
                                        <div className="candle-52" />
                                      </div>
                                    </div>
                                    <div className="column-57">
                                      <div className="overlap-7">
                                        <img className="stick-35" alt="Stick" src="/img/stick-19.svg" />
                                        <div className="candle-53" />
                                      </div>
                                    </div>
                                    <div className="column-58">
                                      <div className="overlap-22">
                                        <img className="stick-36" alt="Stick" src="/img/stick-18.svg" />
                                        <div className="candle-54" />
                                      </div>
                                    </div>
                                    <div className="column-59">
                                      <div className="overlap-35">
                                        <img className="stick-37" alt="Stick" src="/img/stick-17.svg" />
                                        <div className="candle-55" />
                                      </div>
                                    </div>
                                    <div className="column-60">
                                      <div className="overlap-34">
                                        <img className="stick-33" alt="Stick" src="/img/stick-16.svg" />
                                        <div className="candle-56" />
                                      </div>
                                    </div>
                                    <div className="column-61">
                                      <div className="overlap-6">
                                        <img className="stick-34" alt="Stick" src="/img/stick-15.svg" />
                                        <div className="candle-57" />
                                      </div>
                                    </div>
                                    <div className="column-62">
                                      <div className="overlap-6">
                                        <img className="stick-38" alt="Stick" src="/img/stick-14.svg" />
                                        <div className="candle-58" />
                                      </div>
                                    </div>
                                    <div className="column-63">
                                      <div className="overlap-35">
                                        <img className="stick-37" alt="Stick" src="/img/stick-13.svg" />
                                        <div className="candle-59" />
                                      </div>
                                    </div>
                                    <div className="column-64">
                                      <div className="overlap-35">
                                        <img className="stick-37" alt="Stick" src="/img/stick-12.svg" />
                                        <div className="candle-60" />
                                      </div>
                                    </div>
                                    <div className="column-65">
                                      <div className="overlap-26">
                                        <img className="stick-39" alt="Stick" src="/img/stick-11.svg" />
                                        <div className="candle-61" />
                                      </div>
                                    </div>
                                    <div className="column-66">
                                      <div className="overlap-36">
                                        <img className="stick-40" alt="Stick" src="/img/stick-10.svg" />
                                        <div className="candle-62" />
                                      </div>
                                    </div>
                                    <div className="column-67">
                                      <div className="overlap-8">
                                        <img className="stick-41" alt="Stick" src="/img/stick-9.svg" />
                                        <div className="candle-63" />
                                      </div>
                                    </div>
                                    <div className="column-68">
                                      <div className="overlap-37">
                                        <img className="stick-42" alt="Stick" src="/img/stick-8.svg" />
                                        <div className="candle-64" />
                                      </div>
                                    </div>
                                    <div className="column-69">
                                      <div className="overlap-10">
                                        <img className="stick-43" alt="Stick" src="/img/stick-7.svg" />
                                        <div className="candle-65" />
                                      </div>
                                    </div>
                                    <div className="column-70">
                                      <div className="overlap-26">
                                        <img className="stick-44" alt="Stick" src="/img/stick-6.svg" />
                                        <div className="candle-66" />
                                      </div>
                                    </div>
                                    <div className="column-71">
                                      <div className="overlap-26">
                                        <img className="stick-44" alt="Stick" src="/img/stick-5.svg" />
                                        <div className="candle-66" />
                                      </div>
                                    </div>
                                    <div className="column-72">
                                      <div className="overlap-26">
                                        <img className="stick-44" alt="Stick" src="/img/stick-4.svg" />
                                        <div className="candle-66" />
                                      </div>
                                    </div>
                                    <div className="column-73">
                                      <div className="overlap-26">
                                        <img className="stick-44" alt="Stick" src="/img/stick-3.svg" />
                                        <div className="candle-67" />
                                      </div>
                                    </div>
                                    <div className="column-74">
                                      <div className="overlap-10">
                                        <img className="stick-45" alt="Stick" src="/img/stick-2.svg" />
                                        <div className="candle-68" />
                                      </div>
                                    </div>
                                    <div className="column-75">
                                      <div className="overlap-38">
                                        <img className="stick-46" alt="Stick" src="/img/stick-1.svg" />
                                        <div className="candle-69" />
                                      </div>
                                    </div>
                                    <div className="column-76">
                                      <div className="overlap-39">
                                        <img className="stick-47" alt="Stick" src="/img/stick.svg" />
                                        <div className="candle-70" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-15">
                <div className="group-16">
                  <p className="data-monitoring-for">Data Monitoring For Market Making</p>
                  <p className="monitor-live-market">
                    Monitor live market-making specific liquidity metrics.
                    <br />
                    Enable market-makers to deliver optimal liquidity and promptly adapt to market dynamics.
                  </p>
                </div>
                <img className="group-17" alt="Group" src="/img/group-11123.png" />
              </div>
            </div>
          </div>
          <div className="simple-centered-dark">
            <div className="menu">
              <div className="group-18">
                <div className="support">Terms and Conditions</div>
                <div className="join-us">Privacy Policy</div>
              </div>
            </div>
            <img className="v" alt="V" src="/img/v-1.png" />
            <p className="copyright">Copyright © 2023 Fourleaf - All Rights Reserved</p>
          </div>
          <div className="frame-16">
            <div className="group-19">
              <div className="overlap-40">
                <div className="IMG-2">
                  <div className="group-20">
                    <div className="group-21">
                      <div className="group-20">
                        <div className="overlap-41">
                          <div className="feature-6">
                            <div className="group-20">
                              <div className="group-21">
                                <div className="overlap-group-7">
                                  <div className="background-3" />
                                  <div className="frame-17" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="group-22">
                            <div className="title-5">Address:</div>
                            <p className="text-wrapper-13">
                              Fourleaf SA
                              <br />
                              50 Av. Marceau, 75008 Paris, France
                            </p>
                          </div>
                          <div className="group-23">
                            <div className="text-wrapper-13">info@fourleaf.io</div>
                            <div className="title-6">Email</div>
                          </div>
                          <div className="group-24">
                            <div className="text-wrapper-13">+33 812 291 129</div>
                            <div className="title-6">Phone</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="IMG-3">
                  <div className="overlap-42">
                    <div className="overlap-43">
                      <img className="vector-3" alt="Vector" src="/img/vector-46.svg" />
                      <img className="vector-4" alt="Vector" src="/img/vector-45.svg" />
                      <img className="vector-5" alt="Vector" src="/img/vector-44.svg" />
                      <img className="vector-6" alt="Vector" src="/img/vector-43.svg" />
                      <img className="vector-7" alt="Vector" src="/img/vector-42.svg" />
                      <img className="vector-8" alt="Vector" src="/img/vector-41.svg" />
                      <img className="vector-9" alt="Vector" src="/img/vector-40.svg" />
                      <img className="vector-10" alt="Vector" src="/img/vector-39.svg" />
                      <img className="vector-11" alt="Vector" src="/img/vector-38.svg" />
                      <img className="vector-12" alt="Vector" src="/img/vector-37.svg" />
                      <img className="vector-13" alt="Vector" src="/img/vector-36.svg" />
                      <img className="vector-14" alt="Vector" src="/img/vector-35.svg" />
                      <img className="vector-15" alt="Vector" src="/img/vector-34.svg" />
                      <img className="vector-16" alt="Vector" src="/img/vector-33.svg" />
                      <img className="vector-17" alt="Vector" src="/img/vector-31.svg" />
                      <img className="vector-18" alt="Vector" src="/img/vector-30.svg" />
                      <img className="vector-19" alt="Vector" src="/img/vector-29.svg" />
                      <img className="vector-20" alt="Vector" src="/img/vector-28.svg" />
                      <img className="vector-21" alt="Vector" src="/img/vector-27.svg" />
                      <img className="vector-22" alt="Vector" src="/img/vector-26.svg" />
                      <img className="vector-23" alt="Vector" src="/img/vector-25.svg" />
                      <img className="vector-24" alt="Vector" src="/img/vector-24.svg" />
                      <img className="vector-25" alt="Vector" src="/img/vector-23.svg" />
                      <img className="vector-26" alt="Vector" src="/img/vector-22.svg" />
                      <img className="vector-27" alt="Vector" src="/img/vector-21.svg" />
                      <img className="vector-28" alt="Vector" src="/img/vector-20.svg" />
                      <img className="vector-29" alt="Vector" src="/img/vector-19.svg" />
                      <img className="vector-30" alt="Vector" src="/img/vector-18.svg" />
                      <img className="vector-31" alt="Vector" src="/img/vector-15.svg" />
                      <img className="vector-32" alt="Vector" src="/img/vector-12.svg" />
                      <img className="vector-33" alt="Vector" src="/img/vector-11.svg" />
                      <img className="vector-34" alt="Vector" src="/img/vector-10.svg" />
                      <img className="vector-35" alt="Vector" src="/img/vector-8.svg" />
                      <img className="vector-36" alt="Vector" src="/img/vector-7.svg" />
                      <img className="vector-37" alt="Vector" src="/img/vector-6.svg" />
                      <img className="vector-38" alt="Vector" src="/img/vector-5.svg" />
                      <img className="vector-39" alt="Vector" src="/img/vector-4.svg" />
                      <img className="vector-40" alt="Vector" src="/img/vector-1.svg" />
                      <img className="vector-41" alt="Vector" src="/img/vector.svg" />
                      <div className="group-25">
                        <div className="ellipse-wrapper">
                          <img className="ellipse-3" alt="Ellipse" src="/img/ellipse-170.svg" />
                        </div>
                      </div>
                    </div>
                    <div className="overlap-44">
                      <img className="vector-42" alt="Vector" src="/img/vector-32.svg" />
                      <img className="vector-43" alt="Vector" src="/img/vector-14.svg" />
                    </div>
                    <img className="vector-44" alt="Vector" src="/img/vector-17.svg" />
                    <img className="vector-45" alt="Vector" src="/img/vector-16.svg" />
                    <div className="overlap-45">
                      <img className="vector-46" alt="Vector" src="/img/vector-13.svg" />
                      <img className="vector-47" alt="Vector" src="/img/vector-9.svg" />
                    </div>
                    <img className="vector-48" alt="Vector" src="/img/vector-3.svg" />
                    <img className="vector-49" alt="Vector" src="/img/vector-2.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="CONTENT-wrapper">
              <div className="CONTENT">
                <div className="heading">
                  <div className="contact-us">Contact Us</div>
                  <p className="text-wrapper-14">We will get back to you within 24 hours</p>
                </div>
                <div className="form">
                  <div className="div-3">
                    <div className="overlap-group-8">
                      <div className="text-wrapper-15">Enter your name</div>
                    </div>
                    <div className="text-wrapper-16">Your Name</div>
                  </div>
                  <div className="div-3">
                    <div className="overlap-group-8">
                      <div className="text-wrapper-15">Enter mail</div>
                    </div>
                    <div className="text-wrapper-16">Email</div>
                  </div>
                  <div className="message">
                    <div className="overlap-46">
                      <div className="text-wrapper-15">Enter your message</div>
                    </div>
                    <div className="text-wrapper-16">Message</div>
                  </div>
                </div>
                <button className="button-3">
                  <div className="overlap-47">
                    <div className="text-wrapper-17">Send message</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
